/****************************** 
*** Home Page Styles ***
******************************/

.app {
    display: flex;
    font-family: 'Quicksand';
    overflow-x: hidden;
}
.page {
    margin: auto;
    text-align: center;
}

.spacing {
    background-color: #d6e1ee;
}


.header {
    width: auto;
    font-size: 100px;
    margin: 0;
    text-align: center;
    text-decoration: underline;
}

.plaintext {
    font-size: 40px;
    text-align: center;
}

.coloredBkg {
    background-color: #292F36;
    width: calc(99.5vw);
    margin-left: calc(-50vw + 50% + 0px);
    padding: 50px 0px 50px 0px;
}

.coloredBkgContainer {
    width: 80%;
    margin: auto;
    text-align: center;
}

.plaintext-alt {
    font-size: 40px;
    text-align: center;
    color: white;
}

.listitems {
    font-size: 35px;
    color: white;
    list-style: circle black;
    text-align: left;
    padding: 0 0 50px 0;
}

.header-alt {
    width: auto;
    font-size: 100px;
    margin: 0;
    text-align: center;
    text-decoration: underline;
    color: #f9be2c;
}

.btn-yellow {
    margin-top: 30px;
    width: auto;
    height: 120px;
    background-color: #AB8830;
    font-size: 50px;
    padding: 0 120px 0 120px;
}

.btnlink {
    color: black;
    text-decoration: none;
}

.divider {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.divider div hr {
    border-width: thin;
    width: 80%;
    border-color: black;
}

.divider-logo {
    height: 100px;
    justify-self: center;
}

.bkgImgDiv {
    position: relative;
    z-index: 1;
    text-align: left;
}

.fullWidthImg {
    width: calc(100vw);
    margin-left: calc(-50vw + 50% + 0px);
    position: absolute;
    left: 0;
    z-index: 2;
    height: -webkit-fill-available;
}

.overlay {
    display: block;
    width: calc(100vw);
    margin-left: calc(-50vw + 50% + 0px);
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    z-index: 3;
    padding-top: 50px;
}

.overlayTxt {
    width: 80%;
    margin: auto;
    padding: 50px 0 50px 0;
}

/*******************
*** Mobil Styles ***
*******************/

@media screen and (max-width: 450px) {

}


/********************* 
*** Desktop Styles ***
*********************/

@media screen and (min-width: 1024px) {
    /* Home Page Styles */

    .page {
        width: 100%;
        text-align: -webkit-center;
    }


    /* About Babblebot Mini Section */

    .bkgImgDiv {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        position: relative;
        height: 550px;
        overflow: hidden;
    }

    .fullWidthImg {
        grid-area: 1 / 1 / 2 / 2;
        position: absolute;
        left: 0;
        height: -webkit-fill-available;
        margin: 0;
        width: 120%;
    }

    .overlay {
        grid-area: 1 / 2 / 2 / 3;
        display: block;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        padding-top: 50px;
        width: 80%;
        justify-self: right;
    }

    .overlay .header {
        width: auto;
        font-size: 50px;
        margin: 0;
        text-align: center;
        text-decoration: underline;
    }

    .overlay .overlayTxt {
        width: 90%;
        margin: auto;
        padding: 50px 0 50px 0;
        font-size: 20px;
    }

    /* Sign-in page styles */

    .babblebotSignup {
        height: 200px;
        padding-top: 20px;
    }
}