.supportPage {
    width: 50%;
    text-align: left;
    margin: 0 auto;
}

.statusMessage {

}

.formContainer {
    width: 90%;
}

.formContainer p {
    width: 80%;
}

.supportForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nameAndEmailContainer {
    width: 91.5%;
    display: flex;
    justify-content: space-between;
}

.nameAndEmailContainer input {
    width: 45%;
    padding: 4px;
}

.supportFormInput {
    margin-bottom: 20px;
    font-size: 20px;
    padding: 4px;
    width: 90%;
    border-radius: 4px;
    border: none;
    background-color: white;
}

.supportTextArea {
    background-color: white;
    border: none;
    resize:vertical;
    max-width: 90%;
    min-height: 150px;
}

.formSubmitButton {
    background-color: #6DA34D;
    color: white;
    border: 1px solid #6DA34D;
    padding: 8px 16px;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.formSubmitButton:hover {
    cursor: pointer;
    color: #6DA34D;
    background-color: white;
}