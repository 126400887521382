@media screen and (max-width: 1000px) {
    .fullPost {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: auto;
    }

    .postInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .postInfo img {
        border-radius: 10px;
        border: 1px solid black;
        margin: 20px 0 0 0;
    }

    .postInfo a {
        align-self: flex-start;
        margin: 20px 0 0 0;
        color: #276FBF;
        text-decoration: none;
    }

    .postInfo .topicsTitle {
        margin: 0;
        text-decoration: underline;
    }

    .postInfo .tags {
        margin: 2px 0;
    }
}

@media screen and (min-width: 1000px) {
    .fullPost {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    
    .postInfo {
        width: 50%;
        padding: 30px 30px 30px 100px;
        position: sticky;
        display: flex;
        flex-direction: column;
    }
    
    .postInfo a {
        color: #276FBF;
        text-decoration: none;
        transition: 0.2s ease-in-out;
    }
    
    .postInfo a:hover {
        text-decoration: underline;
    }
    
    .postInfo img {
        margin: 20px 0 0 0;
        border-radius: 4px;
        border: 1px solid black;
        max-height: 400px;
        max-width: min-content;
    }
    
    .postInfo p {
        
    }
    
    .postInfo .topicsTitle {
        text-decoration: underline;
        margin-bottom: 2px;
    }
    
    .postInfo .tags {
        margin: 0;
    }
    
    .postBody {
        padding: 30px;
        width: fit-content;
        background-color: rgb(247, 246, 240);
    }
    
    .postBody div {
        width: 80%;
    }
    
    .postBody h1 {
        margin: 0;
    }    
}
