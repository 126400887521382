.ticketsPage {
    width: 70%;
    margin: 0 auto;
}

.ticketsContainer {
    width: 100%;
    margin: auto;
}

.ticketInfoContainer {
    width: 100%;
    margin-bottom: 5px;
}

.ticketMainInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border: 2px solid black;
    padding: 0 10px;
    border-radius: 4px;
}

.ticketMainInfo:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.ticketDescription {
    width: 95%;
    background-color: white;
    display: flex;
    border: 1px solid black;
    border-top: none;
    margin: auto;
}

.ticketDescription p {
    margin: 0;
}

.ticketInfoRow {
    padding: 20px 10px 20px 10px;
    display: flex;
    align-items:baseline;
}

.ticketInfoTitle {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    min-width: 15%;
}

.ticketIssue {
    font-weight: bold;
}


.statusContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-weight: bold;
}

.statusColor {
    border: 1px solid black;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}