@media screen and (min-width: 1024px) {
    .signupPage {
        background-color: beige;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90vh;
        text-align: -webkit-center;
    }
    
    .registerContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 100px 50px;
        border: solid 1px #292F36;
        width: 50%;
    }

    .signUpHeader {
        color: #292F36;
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 0;
        margin-bottom: 50px;
    }

    .nameInputs {
        display: flex;
        width: 70%;
        margin: auto;
        justify-content: space-between;
    }

    .nameInputs .fName {
        width: 45%!important;
    }

    .nameInputs .lName {
        width: 45%!important;
    }
    
    .registerInput {
        height: 30px;
        width: 70%;
        place-self: center;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0px;
        font-size: large;
        margin-bottom: 10px;
    }

    .showPass {
        text-align: right;
        width: 70%;
        margin: auto;
    }
    
    .registerBtn {
        border: none;
        background-color: #276FBF;
        color: white;
        font-size: 20px;
        padding: 8px 12px;
        width: 35%;
        border-radius: 8px;
        margin: 20px 0 10px 0;
        align-self: center;
    }    

    .registerBtn:hover {
        border: inset 1px #276FBF;
        background-color: transparent;
        color: #000;
        cursor: pointer;
    }

    .registerContainer p {
        font-size: 16px;
    }

    .alreadyHaveAnAccountContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .alreadyHaveAnAccountContainer p {
        margin-right: 5px;
    }

    .alreadyHaveAnAccountContainer .signUpLink {
        text-decoration: none;
    }
}
