.generateBtn {
    background-color: #F3C03B;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 20px;
    transition: 0.2s ease-in-out;
}

.generateBtn:hover {
    cursor: pointer;
    background-color: rgb(243, 192, 59, 0.8);
    transform: scale(1.2);
}

.generatedContentSection {
    display: flex;
    border: none;
    margin: -5px 0 5px 0;
}

.bookCover {
    width: 80%;
    box-shadow: 0px 4px 0px 4px;
    background-size: cover;
    border-radius: 12px;
    padding: 15px 5px 2px 5px;
    position: relative;
}

.bookPages {
    margin: -5px auto 2px auto;
    box-shadow: inset 0px -10px 10px -3px;
    padding: 0 5px;
    background-color: white;
    border: 1px solid black;
}

.Outer {
    margin: -5px auto 5px auto;
}

.generatedImgArea {
    width: 50%;
    border: 1px solid black;
    border-right: none;
    box-shadow: inset -15px 0px 15px -10px;
    padding: 100px 0px;
    border-radius: 0 8px 0 0;
    background-color: white;
    position: relative;
}

.recording {
    display: none!important;
}

.recordingIndicator {
    position: absolute;
    top: 25px;
    left: 25px;
}

.audio-recorder .audio-recorder-mic {
    display: none!important;
}

.leftArrow {
    position: absolute;
    bottom: 25px;
    left: 25px;
    border-radius: 50%;
    padding: 10px;
    background-color: #F3C03B;
    transition: 0.2s ease-in-out;
}

.leftArrow:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.rightArrow {
    position: absolute;
    right: 25px;
    bottom: 25px;
    border-radius: 50%;
    padding: 10px;
    background-color: #F3C03B;
    margin: 0;
    transition: 0.2s ease-in-out;
}

.rightArrow:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.generatedStoryArea {
    width: 50%;
    border: 1px solid;
    border-left: none;
    box-shadow: inset 15px 0px 15px -10px;
    border-radius: 8px 0 0 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 30px;
    font-weight: bold;
}

.finishBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: 25px;
    right: 25px;
}

.finishIcon {
    height: 35px;
    color: #AA4747;
    transition: 0.2s ease-in-out;
}

.finishIcon:hover {
    transform: scale(1.5);
    cursor: pointer;
}