body {
  margin: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: auto;
}

.logoLink {
  display: flex;
  text-decoration: none;
  align-items: center;
}

.logoLink h1 {
  text-decoration: none;
  font-size: 50px;
  margin: 0;
  color: #8b8f97;
  letter-spacing: -5px;
}

