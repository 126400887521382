.unconfiguredClassroom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.unconfiguredClassroom h2 {
    font-size: 30px;
}

.setupClassroomBtnContainer {
    text-align: center;
    width: 100%;
}

.unconfiguredClassroom button {
    border: 1px solid #276FBF;
    color: #fff ;
    background-color: #276FBF;
    padding: 8px 15px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    font-size: 16px;
    margin-top: 20px;
    width: 40%;
}

.unconfiguredClassroom button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #276FBF ;
    padding: 8px 15px;
    border-radius: 5px;
}

.modal-content {
    width: 40%;
}

.inputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.inputContainer label {
    font-size: 20px;
    margin-bottom: 5px;
}

.inputContainer select {
    font-size: 18px;
    background-color: transparent;
    transition: 0.2s ease-in-out;
}

.inputContainer select:hover {
    cursor: pointer;
    transform: scale(1.02);
}