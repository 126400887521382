.studentDashPage {
    min-height: 80vh;
    background-repeat: repeat-x;
    background-size: cover;
    display: flex;
}

.studentDash {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    margin: auto;
}
    
.studentDashLeft { 
    margin-top: 10%;
    position: relative;
 }

.speechBubble {
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    padding: 40px;
    border-radius: .4em;
    font-size: 20px;
    margin: 0;
    border: 1px solid black;
    margin: 0 -50px 0 0;
}

.speechBubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 30%;
	width: 0;
	height: 0;
	border: 31px solid transparent;
	border-top-color: #ffffff;
	border-bottom: 0;
	border-left: 0;
	margin-left: -15.5px;
	margin-bottom: -31px;
}

.studentDashRight { 
    width: 100%;
    margin: 0 7%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.currentLesson {
    background: radial-gradient(circle at 100%, #276FBF, #276FBF 50%, #fff 75%);
    border: 2px solid black;
    border-radius: 8px;
    font-size: 25px;
    padding: 10px;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 80%;
    justify-content: space-between;
}

.lessonDetails {
    display: flex;
}

.lessonDetails .detailIcon {
    height: 60px;
    margin-right: 15px;
    color: #276FBF;
}

.currentLesson p {
    margin: 5px;
    font-weight: bold;
}

.currentLesson button {
    padding: 8px 34px;
    place-self: end;
    font-size: 20px;
}

.studentProfile {
    grid-area: 1 / 2 / 3 / 3;
    background-color: rgb(238, 238, 238, 0.9);
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.studentProfile .profilePic {
    height: 50px;
    border: 1px solid black;
    border-radius: 50%;
}

.studentDetails {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 10px 0;
}

.studentDetails div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.studentDetails div h4 {
    margin: 5px 0;
}

.studentDetails div p {
    margin: 0;
    font-size: 24px;
    align-content: center;
}

.streakContainer {
    position: relative;
}

.streakIcon {
    height: 48px;
    color: rgb(239, 124, 16);
    border: none;
}

.streakNum {
    position: absolute;
    color: black;
    top: 18px;
    font-weight: bold;
    background-color: rgb(238, 238, 238, 0.9);
    border-radius: 50%;
}

.studentDetails .gradeNum {
    background-color: #264941;
    border: 2px solid #966115;
    font-weight: bold;
    color: white;
    height: 45px;
    width: 70px;
}

.wordOfTheDay {
    font-size: 20px;
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.word {
    margin: 0 0 0 5px;
    text-decoration: underline;
}

.word:hover {
    color: #276FBF;
    cursor: help;
}

.studentNav {
    position: absolute;
    left: 50%;
    background-color: #eee;
    padding: 10px;
    border-radius: 0 0 8px 8px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;    
}

.studentNavIcon {
    padding: 0 10px;
    height: 30px;
    color: black;
    transition: 0.2s ease-in-out;
    position: relative;
}

.studentNavIcon:hover {
    transform: scale(1.05);
    cursor: pointer;
    color: #276FBF;
}

.selectionContainer {
    display: flex;
    padding-top: 5px;
}

#backgroundSelect {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 125px;
    background-color: #eee;
    border-radius: 0 0 8px 8px;
    border: 1px solid black;
    border-top: none;
    padding: 0 10px;
}

.backgroundImgContainer {
    display: flex;
    padding: 10px;
}

#backgroundSelect .individualImgContainer {
    padding: 5px 0;
}

.individualImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.individualImgContainer:hover {
    background-color: rgba(62, 54, 54, 0.1);
    border-radius: 4px;
    cursor: pointer;
}

.individualImgContainer p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

#backgroundSelect img {
    height: 25px;
    width: 50px;
    margin: 0 10px;
}

#profilePicSelect {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 125px;
    background-color: #eee;
    border-radius: 0 0 8px 8px;
    border: 1px solid black;
    border-top: none;
    padding: 0 10px;
}

.profileImgContainer {
    display: flex;
    padding: 10px;
}

#profilePicSelect img {
    height: 40px;
    width: 40px;
    margin: 0 10px;
}

