.plansPage {
    background-color: rgb(247, 246, 240);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.plansHeader {
    margin: auto;
    width: 80%;
}

.plansHeader h2 {
    color: #000;
    font-size: 40px;
    margin: 0 0 20px 0;
}

.plansContainer {
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.plan {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 29%;
}

.plan h3 {
    font-size: 28px;
    margin: 0;
}

.plan .maxStudents {
    margin: 2px 0 0 0;
    font-weight: bold;
}

.plan .maxStudents span {
    color: #276FBF;
}

.plan b {
    font-size: 40px;
}

.plan button {
    padding: 8px 50px;
    border: 1px solid #276FBF;
    border-radius: 4px;
    background-color: #276FBF;
    color: white;
    transition: 0.2s ease-in-out;
}

.plan button:hover {
    cursor: pointer;
    border: 1px solid #276FBF;
    color: #276FBF;
    background-color: white;
}

.plan .planIncludes {
    font-weight: bold;
}

.plan ul {
    font-weight: 500;
    list-style-type: circle;
}

.plan ul li {
    margin-bottom: 5px;
}