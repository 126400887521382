

.badgesContainer {
    background-color: rgb(238, 238, 238, 0.9);
    border: 2px solid black;
    border-radius: 10px;
    grid-area: 3 / 1 / 3 / 3;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.badgesContainer h3 {
    font-size: 30px;
    margin: 0;
}

.badgesContainer div {
    display: flex;
    align-content: center;
    align-items: center;
}

.icon-wrapper {
    position: relative;
    display: inline-block;
}

.icon-wrapper .trophy {
    height: 50px;
    color: #ffd700;
    margin: 0 10px 0 0;
    position: relative;
}

.icon-wrapper p {
    position: absolute;
    left: 25%;
    top: 10%;
    font-size: 10px;
}